<template>
  <b-card :title="$t('packaging')">
    <b-row>
      <b-col md="3" class="mb-2">
        <b-input-group class="mt-3">
          <b-button-group>
            <b-button size="sm" variant="outline-secondary" title="Скинути фільтр" @click="clearFilter">
              <fa icon="filter" />
            </b-button>
            <b-button size="sm" variant="outline-dark" @click="hasOneRowOpen ? hideAllDetails() : showAllDetails()">{{ hasOneRowOpen ? 'Згорнути' : 'Розгорнути'}}</b-button>
          </b-button-group>
        </b-input-group>
      </b-col>
      <b-col md="2" class="mb-2">
        <!--<b-input-group prepend="ID" class="mt-3">
          <b-form-input size="sm"></b-form-input>
        </b-input-group>-->
      </b-col>
      <b-col v-if="registerRef" md="6" class="mb-2 text-right">
        <b-button size="sm" variant="outline-dark" title="Додати в текучий реєстр" @click="addToCurrentRegister">
          Додати в текучий реєстр
        </b-button>
        <b-button size="sm" variant="outline-danger" title="Додати в текучий реєстр" @click="cancelAddToCurrentRegister">
          Відміна
        </b-button>
      </b-col>
      <b-col v-else md="7" class="mb-2 text-right">
        <b-button-group class="mt-3">
            <b-dropdown text="Сформувати реєстр" size="sm" variant="outline-dark">
              <b-dropdown-item href="#" @click="formRegisterByProvider('ukr-posta')">Укр-пошта</b-dropdown-item>
              <b-dropdown-item href="#" @click="formRegisterByProvider('nova-posta')">Нова-пошта</b-dropdown-item>
            </b-dropdown>
            <b-dropdown text="Робота з реєстром" size="sm" variant="outline-dark">
              <b-dropdown-item href="#" @click="openRegister('ukr-posta')" v-b-modal.modal-register>Укр-пошта</b-dropdown-item>
              <b-dropdown-item href="#" @click="openRegister('nova-posta')" v-b-modal.modal-register>Нова-пошта</b-dropdown-item>
            </b-dropdown>
            <b-dropdown text="Етикетки" size="sm" variant="outline-dark">
              <b-dropdown-item href="#" @click="printMarkings('100x100', 'pdf')">Зебра 100x100</b-dropdown-item>
              <b-dropdown-item href="#" @click="printMarkings('100x100', 'pdf8')">А4 100х100</b-dropdown-item>
              <b-dropdown-item href="#" @click="printMarkings('85x85', 'pdf')">Зебра 85х85</b-dropdown-item>
              <b-dropdown-item href="#" @click="printMarkings('85x85', 'pdf8')">А4 85х85</b-dropdown-item>
            </b-dropdown>
            <b-dropdown text="Сформувати ТТН" size="sm" variant="outline-dark">
              <b-dropdown-item href="#" @click="openCreateDocumentModal('docs', 'all')">Всі без ТТН</b-dropdown-item>
              <b-dropdown-item href="#" @click="openCreateDocumentModal('docs', 'ukr-posta')">Всі без ТТН УкрПошта</b-dropdown-item>
              <b-dropdown-item href="#" @click="openCreateDocumentModal('docs', 'nova-posta')">Всі без ТТН НоваПошта</b-dropdown-item>
              <b-dropdown-item href="#" @click="openCreateDocumentModal('selected', '-')">Відмічені Без ТТН</b-dropdown-item>
              <b-dropdown-item href="#" @click="openCreateDocumentModal('renew', '-')">Відмічені Перестворення ТТН</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
      </b-col>
    </b-row>
    <b-table
    responsive
    show-empty
    sort-icon-left
    :items="items"
    :fields="fields"
    :current-page="currentPage"
    :per-page="0"
    v-model="currentItems">
      <template slot="top-row" slot-scope="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @change="searchByText(field.key, $event)"></b-form-input>
          <b-input-group v-if="field.typesort === 'doubleinput'">
            <b-form-input @change="searchByDate('start_date', $event)" type="date" size="sm"></b-form-input>
            <b-form-input @change="searchByDate('end_date', $event)" type="date" size="sm"></b-form-input>
          </b-input-group>
          <!-- delivery -->
          <div v-if="field.key === 'delivery'" class="form-group">
            <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
              <option value="">--</option>
              <option v-for="item in deliveries" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <!-- payment -->
          <div v-if="field.key === 'payment_type'" class="form-group" >
            <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
              <option value="">--</option>
              <option v-for="item in payments" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <!-- payment status id -->
          <div v-if="field.key === 'payment_status_id'" class="form-group">
            <select class="form-control form-control-sm"  @change="searchBySelect(field.key, $event)">
              <option value="">--</option>
              <option v-for="item in paymentStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <!-- order status id -->
          <div v-if="field.key === 'order_status'" class="form-group">
            <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
              <option value="">--</option>
              <option v-for="item in orderStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
        </td>
      </template>
      <template v-slot:head(selected)>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="check-package" @click="selectAll"/>
          <label class="custom-control-label" for="check-package"></label>
        </div>
      </template>
      <template v-slot:cell(selected)="row">
        <b-button-group>
          <div class="custom-control custom-checkbox">
              <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-${row.item.id}`"
              :name="`checkbox-${row.item.id}`"
              :value="row.item.id"
              v-model="selectIds"
              @change="selectOrder">
              <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
          </div>
          <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
            <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
          </b-button>
        </b-button-group>
      </template>
      <template v-slot:cell(internet_document)="row">
        <div style="width:170px">{{ row.item.internet_document }}
          <b-button 
          v-if="row.item.internet_document"
          @click.stop="removeInternetDocument(row.item.id, row.item.internet_document)"
          :title="$t('remove_internet_document')"
          size="sm" pill variant="outline-danger"><fa icon="trash" /></b-button>
        </div>
      </template>
      <template v-slot:cell(printed)="row">
        <span v-if="row.item.printed">
          <b-badge variant="success">{{ $t('yes') }}</b-badge>
          <button
          class="btn btn-secondary btn-sm"
          type="button"
          @click="cancelPrint(row.item.internet_document)">{{ $t('cancel') }}</button>
        </span>
        <b-badge v-else variant="info">{{ $t('no') }}</b-badge>
      </template>
      <template v-slot:cell(order_status)="row">
        <b-badge v-if="row.item.order_status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
        <b-badge v-else variant="primary">{{ row.item.order_status }}</b-badge>
      </template>
      <template v-slot:cell(delivery_status)="row">
        <b-badge variant="primary">{{ row.item.delivery_status }}</b-badge>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button-group>
        <NPBtnPrint v-if="row.item.delivery_method === 'nova-posta'" :item="row.item" />
        <UPBtnPrint v-if="row.item.delivery_method === 'ukr-posta'" :item="row.item" />
          <b-button v-if="row.item.package_status === 0 || row.item.package_status === 2" size="sm" variant="outline-primary" @click="setPackageStatus(row.item.pid, 1)">{{ $t('to_pack') }}</b-button>
          <b-button v-if="row.item.package_status === 1" size="sm" variant="outline-danger" @click.once="setPackageStatus(row.item.pid, 2)">{{ $t('cancel') }}</b-button>
          <b-button v-if="!row.item.internet_document && row.item.delivery_method === 'nova-posta'" :title="$t('get_internet_document')" size="sm" variant="outline-dark" @click="getTtn(row.item.id)">ТТН</b-button>
          <b-button v-if="row.item.scan_sheet === 'null'" @click="toRegister(row.item.id)" size="sm" variant="outline-dark" title="Додати в реєстр">
            <fa icon="registered" />
          </b-button>
          <!--<b-button v-if="row.item.internet_document" size="sm" variant="outline-dark" title="Переоформити ТТН">
            <fa icon="sync" />
          </b-button>-->
          <b-button @click="logInfo(row.item.id)" size="sm" variant="outline-dark" title="Інформація" v-b-modal.modal-log>
            <fa icon="info-circle" />
          </b-button>
        </b-button-group>
      </template>
      <template v-slot:row-details="row">
        <table class="table-sm table-borderless">
          <tbody>
            <template v-for="item in row.item.products.data">
              <tr class="border-top">
                <td><strong>Група:</strong></td>
                <td><strong>{{ item.group }}</strong></td>
              </tr>
              <tr>
                <td>Товар:</td>
                <td><strong>{{ item.product }}</strong></td>
              </tr>
              <tr v-if="item.characteristic">
                <td>{{ $t('characteristic') }}:</td>
                <td><strong>{{ item.characteristic }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('quantity') }}:</td>
                <td>
                  <strong>{{ item.quantity }}</strong>
                  <span class="ml-2">
                    {{ $t('product_status') }}:
                    <b-badge v-if="item.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Новий'" variant="primary">{{ $t('new') }}</b-badge>
                    <b-badge v-else-if="item.status === 'В роботі'" variant="warning">{{ $t('in_work') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Не укомплектовано'" variant="danger">{{ item.status }}</b-badge>
                    <b-badge v-else variant="secondary">{{ item.status }}</b-badge>
                  </span>
                </td>
              </tr>
              <template v-if="(item.stages || []).length > 0" v-for="stage in item.stages">
                <tr>
                  <td>{{ $t('stage') }}:</td>
                  <td>
                    <strong>{{ stage.stage }}</strong>
                  </td>
                </tr>
                <tr>
                  <td><strong>{{ $t('status') }}:</strong></td>
                  <td>
                    <b-badge v-if="stage.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                    <b-badge v-else-if="stage.status === 'Новий'" variant="primary">{{ $t('new') }}</b-badge>
                    <b-badge v-else-if="stage.status === 'Відміна'" variant="danger">{{ $t('cancel') }}</b-badge>
                    <span class="ml-2">
                      {{ stage.ready }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-img-lazy :src="apiUrl + stage.photo" fluid width="150" thumbnail />
                  </td>
                  <td>
                    <b-img-lazy :src="apiUrl + stage.photo_layout" fluid width="150" thumbnail />
                  </td>
                </tr>
              </template>
            </template>
            <tr class="border-bottom">
              <td colspan="2">
                <b-button v-if="row.item.order_status === 'Готово'" size="sm" variant="success" @click="setPackageStatus(row.item.id, row.item.packedStatus)">{{ $t('packed') }}</b-button>
                <b-button v-if="row.item.order_status === 'Упаковано'" size="sm" variant="danger" @click.once="setPackageStatus(row.item.id, row.item.readyStatus)">{{ $t('cancel') }}</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </b-table>
    <b-modal id="modal-log" hide-footer>
      <template #modal-title>
        {{ $t('order_log_title') }}
      </template>
      <div class="table-responsive">
        <table v-if="logs.length > 0" class="table">
          <thead>
            <tr>
              <th>{{ $t('created') }}</th>
              <th>{{ $t('message') }}</th>
              <th>{{ $t('user') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in logs" :key="index">
              <td>{{ item.created_at }}</td>
              <td>
                <p class="text-success" :class="{ 'text-danger': item.status === 0 }">{{ item.message }}</p>
              </td>
              <td>{{ item.user }}</td>
            </tr>
          </tbody>
        </table>
        <b-alert v-else variant="info" show>{{ $t('empty')}}</b-alert>
      </div>
    </b-modal>
    <b-modal id="modal-register" size="lg" ref="registerModal" hide-footer>
      <template #modal-title>
        Робота з реєстром
      </template>
      <component :is="providerType" />
    </b-modal>
    <b-modal id="form-ttn" size="sm" hide-footer>
      <template #modal-title>Створити ТТН на вибрану дату</template>
      <div>
        <b-form-datepicker
        id="example-datepicker"
        class="mb-2"
        locale="uk"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        v-model="documentDate"></b-form-datepicker>
        <button class="btn btn-success" type="button" @click="toFormTtnSelected">Створити</button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
import NPBtnPrint from './print/novaposhtaBtn'
import UPBtnPrint from './print/ukrposhtaBtn'
import NPRegister from './register/novaPoshta'
import UPRegister from './register/ukrPoshta'
export default {
  middleware: 'auth',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('packaging') }
  },
  components: {
    NPBtnPrint,
    UPBtnPrint,
    'nova-posta': NPRegister,
    'ukr-posta': UPRegister
  },
  data () {
    return {
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'order_num',
          label: '# зам.',
          typesort: 'text',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Дата',
          typesort: 'doubleinput',
          sortable: true
        },
        {
          key: 'delivery',
          label: 'Доставка',
          typesort: 'select',
          sortable: true
        },
        {
          key: 'order_status',
          label: 'Статус зам.',
          class: 'text-center',
          typesort: 'select',
          sortable: true
        },
        {
          key: 'delivery_status',
          label: 'Статус дост.',
          class: 'text-center',
          typesort: 'text',
          sortable: true
        },
        {
          key: 'internet_document',
          label: 'ТТН',
          typesort: 'text'
        },
        {
          key: 'printed',
          label: 'Друк',
          class: 'text-center'
        },
        {
          key: 'scan_sheet',
          label: '№ в реєстрі',
          typesort: 'text'
        },
        {
          key: 'sender',
          label: 'Відправник',
          typesort: 'text',
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
          sortable: false
        }
      ],
      currentItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      urlParams: {},
      selected: [],
      selectIds: [],
      selectIdStages: [],
      selectProductStages: [],
      selectedData: [],
      apiUrl: process.env.VUE_APP_ENDPOINT_URL,
      register: null,
      logs: [],
      providerType: null,
      createDocumentProvider: null,
      createDocumentType: '*',
      documentDate: ''
    }
  },
  mounted () {
    this.fetchData().catch(error => {
      console.log(error)
    })
    this.setStatuses()
      if (this.deliveries === null || this.payments === null) {
        this.setDeliveries()
        this.setPayments()
    }
    this.setRegister({ type: null, ref: null })
  },
  computed: {
    ...mapGetters({
      deliveries: 'order/deliveries',
      payments: 'order/payments',
      statuses: 'order/statuses',
      registerRef: 'register/registerRef',
      registerType: 'register/registerType'
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    orderStatuses () {
      if (this.statuses) {
        return this.statuses.filter(s => s.type === 'order')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    paymentStatuses () {
      if (this.statuses) {
        return this.statuses.filter(s => s.type === 'payment')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    hasOneRowOpen () {
      for (const item of this.items) {
        if (item._showDetails) {
          return true
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setDeliveries: 'order/setDeliveries',
      setPayments: 'order/setPayments',
      setStatuses: 'order/setStatuses',
      setRegister: 'register/setRegisterRef'
    }),
    async removeInternetDocument (id, code) {
      if (code) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'packaging/removettn/' + id, { ttn: code })
        this.fetchData()
        this.$root.$refs.loading.finish()
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error_ttn'),
          text: 'ТТН не знайдено!',
          reverseButtons: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        })
      }
    },
    async logInfo (id) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'packaging/logs/' + id)
      this.logs = data
      this.$root.$refs.loading.finish()
    },
    openRegister (type) {
      this.providerType = type
    },
    clearSelected () {
      this.selected = []
      this.selectIds = []
      this.selectIdStages = []
      this.electProductStages = []
      this.selectedData = []
    },
    async formRegisterByProvider (type) {
      if (this.selectedData.length > 0) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'reg/form', {
          type: type,
          selected: this.selectedData
        }).then(resp => {
          if (resp.data.status) {
            this.clearSelected()
            this.providerType = type
            this.$bvModal.show('modal-register')
            this.$root.$refs.loading.finish()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Помилка додавання в реєстр',
              text: resp.data.msg,
              reverseButtons: true,
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel')
            })
            
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Замовлення не вибрано',
          text: 'Виберіть замовлення зі списку, а потім натисніть сформувати реєстр',
          reverseButtons: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        })
      }
    },
    async addToCurrentRegister () {
      if (this.registerRef) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'reg/add', {
          type: this.registerType,
          ref: this.registerRef,
          selected: this.selectedData
        }).then(resp => {
          if (resp.data.status) {
            this.providerType = this.registerType
            this.$bvModal.show('modal-register')
            this.clearSelected()
            this.setRegister({ type: null, ref: null })
          }
        })
      }
    },
    cancelAddToCurrentRegister () {
      this.clearSelected()
      this.setRegister({ type: null, ref: null })
    },
    async toRegister (orderId) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'packaging/register', { order_id: orderId })
      this.$root.$refs.loading.finish()
    },
    async printMarkings (size, type) {
      if (this.selectIds.length > 0) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.post(apiUrl + 'packaging/printbulk', { orders: this.selectIds, size: size, type: type })
        window.open(data, '_blank')
        this.clearSelected()
        this.$root.$refs.loading.finish()
        this.fetchData()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Замовлення не вибрано',
          text: 'Виберіть замовлення зі списку',
          reverseButtons: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        })
      }
    },
    async cancelPrint (ttn) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.post(apiUrl + 'packaging/cancelprint', { ttn: ttn })
      this.$root.$refs.loading.finish()
      this.fetchData()
    },
    async setPackageStatus (orderId, statusId) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'packaging/status', { orderId: orderId, statusId: statusId })
      this.fetchData()
      this.$root.$refs.loading.finish()
    },
    async getTtn (packageId) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'packaging/ttn', { package_id: packageId }).then(resp => {
        if ((resp.data || []).length === 0) {
          Swal.fire({
            icon: 'error',
            title: this.$t('error_ttn'),
            text: this.$t('error_ttn_txt'),
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
        }
      })
      this.fetchData()
      this.$root.$refs.loading.finish()
    },
    openCreateDocumentModal (type, provider) {
      if ((type === 'selected' || type === 'renew') && this.selectedData.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Замовлення не вибрано',
          text: 'Виберіть замовлення зі списку для того щоб додати їх в чергу на створення ТТН',
          reverseButtons: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        })
      } else {
        this.$bvModal.show('form-ttn')
        this.createDocumentProvider = provider
        this.createDocumentType = type
      }
    },
    async toFormTtnSelected () {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'packaging/toformttn', {
          type: this.createDocumentType,
          provider: this.createDocumentProvider,
          date: this.documentDate,
          selected: this.selectedData
        }).then(resp => {
          if (resp.data) {
            this.documentDate = ''
            this.$bvModal.hide('form-ttn')
            Swal.fire({
              icon: 'info',
              title: this.$t('info_queue'),
              text: this.$t('info_text_queue'),
              reverseButtons: true,
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel')
            })
          }
          this.$root.$refs.loading.finish()
        })
    },
    selectAll (e) {
      this.selectIds = []
        if (e.target.checked) {
          for (const item in this.items) {
            this.selectIds.push(this.items[item].id)
            const order = this.items.find(o => o.id === this.items[item].id)
            if (order.products.data) {
              const products = []
              order.products.data.forEach(p => {
                if (p.stages) {
                  const stages = []
                  p.stages.forEach(s => {
                    const st = this.selectIdStages.find(si => si === s.id)
                    if (typeof st === 'undefined') {
                      this.selectIdStages.push(s.id)
                      stages.push(s.id)
                    }
                  })
                  products.push({
                    orderProductId: p.id,
                    stages: stages
                  })
                }
              })
              this.selectProductStages = products
            }
            // set working data
            const wd = this.selectedData.find(w => w.orderId === this.items[item].id)
            if (typeof wd === 'undefined') {
              this.selectedData.push({
                orderId: this.items[item].id,
                chars: this.selectProductStages
              })
            } else {
              this.selectedData = []
              this.selectedData.push({
                orderId: this.items[item].id,
                chars: this.selectProductStages
              })
            }
          }
        } else {
          this.selectIdStages = []
          // set working data
          this.selectedData = []
          this.selectProductStages = []
        }
    },
    selectOrder (e) {
      const id = e.target.value
      if (e.target.checked) {
        const order = this.items.find(o => o.id === id)
        if (order.products.data) {
          order.products.data.forEach(p => {
            if (p.stages) {
              p.stages.forEach(s => {
                this.selectIdStages.push(s.id)
              })
            }
            // with order productId
            this.selectProductStages.push({
              orderProductId: p.id,
              stages: this.selectIdStages
            })
          })
        }
        // set working data
        this.selectedData.push({
          orderId: id,
          chars: this.selectProductStages
        })
      } else {
        const order = this.items.find(o => o.id === id)
        if (order.products.data) {
          order.products.data.forEach(p => {
            if (p.stages) {
              p.stages.forEach(s => {
                const i = this.selectIdStages.findIndex(s => s === s.id)
                this.selectIdStages.splice(i, 1)
              })
            }
          })
        }
        const i = this.selectedData.findIndex(w => w.orderId === id)
        this.selectedData.splice(i, 1)
      }
    },
    selectStage (e, stageId, orderId, productId) {},
    showAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', true)
      })
    },
    hideAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', false)
      })
    },
    async fetchData () {
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'packaging?page=' + this.currentPage + params)
      this.items = data.data
      this.currentPage = data.meta.pagination.current_page
      this.perPage = data.meta.pagination.per_page
      this.totalItems = data.meta.pagination.total
    },
    clearFilter () {
      this.urlParams = {}
      this.fetchData()
    },
    searchByText (key, val) {
      this.urlParams[key] = val
      this.fetchData()
    },
    searchByDate (key, val) {
      this.urlParams[key] = val
      this.fetchData()
    },
    searchBySelect (key, e) {
      const value = e.target.value
      this.urlParams[key] = value
      this.fetchData()
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  }
}
</script>
