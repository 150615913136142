<template>
  <span v-if="item.internet_document">
    <b-button 
      @click="getMarkings(item.id, '100x100', item.internet_document)"
      size="sm"
      variant="outline-dark"
      title="Друк маркіровок 100х100">
      <fa icon="print" /> 100х100
    </b-button>
    <b-button
    @click="getMarkings(item.id, 'A4', item.internet_document)"
    size="sm"
    variant="outline-dark"
    title="Друк бланків А4">
      <fa icon="print" /> A4
    </b-button>
  </span>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    async getMarkings (packageId, type, ttn) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.post(apiUrl + 'packaging/print', { package_id: packageId, type: type, ttn: ttn })
      window.open(data, '_blank')
      this.$root.$refs.loading.finish()
      this.fetchData()
    }
  }
}
</script>
