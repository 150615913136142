<template>
<div v-if="register" class="row">
  <div class="col-sm-4 mb-2">
    <div v-if="senders" class="from-group">
      <label for="">Відправник</label>
      <select class="form-control" @change="setRegister" v-model="selectedKey">
        <option v-for="(item, index) in senderData" :value="item.key" :key="index">{{ item.name }}</option>
      </select>
    </div>
  </div>
  <div class="col-sm-12">
    <b-table
    responsive
    show-empty
    :items="register"
    :fields="fields"
    :current-page="currentPage"
    :per-page="0"
    v-model="currentItems">
    <template v-slot:head(selected)>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="check-packages"/>
          <label class="custom-control-label" for="check-packages"></label>
        </div>
    </template>
    <template v-slot:cell(selected)="row">
        <b-button-group>
          <div class="custom-control custom-checkbox">
              <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-order-${row.item.id}`"
              :name="`checkbox-order-${row.item.id}`"
              :value="row.item.id">
              <label class="custom-control-label" :for="`checkbox-order-${row.item.id}`"></label>
          </div>
          <b-button v-if="row.item.documents.length > 0" size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
            <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
          </b-button>
        </b-button-group>
    </template>
    <template v-slot:cell(printed)="row">
        <button class="btn btn-sm btn-outline-dark" @click="getRegisterLink(row.item.ref)"><fa icon="print" /></button>
    </template>
    <template v-slot:row-details="row">
      <table class="table table-sm">
        <tr>
          <th>ТТН</th>
          <th>Додано</th>
          <th></th>
        </tr>
        <tr v-for="doc in row.item.documents" :key="doc.ref">
          <td>{{ doc.ttn }}</td>
          <td>{{ doc.added }}</td>
          <td>
            <b-button size="sm" variant="outline-danger" @click="removeDocFromRegister('nova-posta', row.item.ref, doc.ref, doc.id)"><fa icon="trash"/></b-button>
          </td>
        </tr>
      </table>
    </template>
    <template v-slot:cell(actions)="row">
      <div class="btn-group">
        <b-button size="sm" variant="outline-primary" @click="addToCurrentRegister('nova-posta', row.item.ref)"><fa icon="plus"/></b-button>
        <b-button size="sm" variant="outline-danger" @click="removeItemFromRegister('nova-posta', row.item.ref)"><fa icon="trash"/></b-button>
      </div>
    </template>
    </b-table>
  </div>
</div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
       fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'datetime',
          label: 'Дата',
        },
        {
          key: 'number',
          label: '№ Реєстру',
        },
        {
          key: 'count',
          label: 'К-сть',
        },
        {
          key: 'printed',
          label: '-',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
          sortable: false
        }
      ],
      currentItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      urlParams: {},
      selected: [],
      selectOrderIds: [],
      selectedData: [],
      register: null,
      printFormatDefaultSelected: 'pdf',
      printMarkingsDefaultSelected: '',
      senders: null,
      selectedKey: null,
      senderData: [],
      defaultKey: null
    }
  },
  mounted() {
    this.openRegister()
    this.getDeliverySenders()
  },
  methods: {
    ...mapActions({
      addRegisterRef: 'register/setRegisterRef',
      setDefaultKey: 'register/setDefaultKey'
    }),
    getRegisterLink (ref) {
      const link = 'https://my.novaposhta.ua/scanSheet/printScanSheet/refs[]/' + ref + '/type/pdf/apiKey/' + this.defaultKey
      window.open(link, '_blank')
    },
    setRegister (e) {
      this.getProviderByKey(e.target.value)
      this.openRegister()
    },
    async getProviderByKey (key) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'deliveries/provider', { key: key }).then(resp => {
        if (resp.data.success) {
          this.setDefaultKey(this.selectedKey)
          this.senderData.push({
            key: key,
            name: resp.data.data[0].Description
          })
          //return resp.data.data
        }
      })
    },
    async getDeliverySenders () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'deliveries/type', {
        type: 'nova-posta'
      }).then(resp => {
        if (resp.data && resp.data.keys) {
          const keys = resp.data.keys.split(',')
          if (keys) {
            this.senders = keys
            keys.forEach(k => {
              this.getProviderByKey(k)
            })
          }
          this.defaultKey = resp.data.defaultKey
        }
      })
    },
    async openRegister () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const key = this.selectedKey ? '&key=' + this.selectedKey : ''
      await axios.get(apiUrl + 'reg?type=' + 'nova-posta' + key).then(resp => {
        if (resp.data) {
          this.register = resp.data
          this.$root.$refs.loading.finish()
        }
      })
    },
    addToCurrentRegister (type, ref) {
      this.addRegisterRef({ type: type, ref: ref })
      this.$bvModal.hide('modal-register')
    },
    async removeItemFromRegister(type, ref) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.delete(apiUrl + 'reg/' + type + '/' + ref).then(resp => {
        this.openRegister()
      })
    },
    async removeDocFromRegister (type, registerRef, ref, id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'reg/ttn', {
        id: id,
        type: type,
        registerRef: registerRef,
        ref: ref
      }).then(resp => {
        this.openRegister()
      })
    },
    selectAllOrder () {},
    selectOrder (e) {}
  }
}
</script>
